import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Keycloak from 'keycloak-js';
import { serverUrl } from './config/config';
import store from './lib/globalStore';

import 'material-icons/iconfont/outlined.css';

import 'koerber-design-system-web/css/bundle.css';
import 'koerber-design-system-web/bundle';

import mitt from 'mitt';
const emitter = mitt();

let keycloak = null;

const app = createApp(App).use(router);

fetch(`${serverUrl}/pub/frontend/config`).then(res => {return res.json()}).then(data => {
    store.store.setKeycloakInitOptions({
        url: data.keycloakAuthUrl,
        clientId: data.keycloakClientId,
        realm: data.keycloakRealmName
    });
    const keycloakInitOptions = store.store.state.keycloakInitOptions;

    try {
        keycloak = new Keycloak(keycloakInitOptions);
    } catch (error) {
        console.log(error);
    }

    document.title = "Line Optimizer Service Center";
    app.config.globalProperties.emitter = emitter;
    app.config.globalProperties.keycloak = keycloak; // load for usage in vue
    window.keycloak = keycloak; // load for usage in js scripts
    window.keycloakInitDone = false;
    app.mount('#app');

    keycloak.init({ onLoad: keycloakInitOptions.onLoad}).then(
        (authenticated) => {
            // console.log("authenticated: ", authenticated);
            if (authenticated) {
                // success
                keycloak.loadUserProfile().then((res) => {
                    store.store.setUser(res);
                });
            }
    })
    .finally(() => {
        // console.log("keycloak init done");
        window.keycloakInitDone = true;
    });

    setInterval(() => {
        try {
            if (keycloak.authenticated) {
                keycloak.updateToken(70).then((refreshed) => {
                    if (refreshed) {
                        console.log('Token refreshed: ' + refreshed);
                    }
                    /*
                    else {
                        console.log('Token not refreshed, valid for ' 
                            + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
                    }
                    */
                }).catch(() => {
                    console.error('Failed to refresh token');
                    window.location.reload();
                });
            } else {
                console.log('Not authenticated, can not refresh token');
            }
        } catch (error) {
            console.log("error in token refresh");
        }
    }, 20000);
}).catch((err) => {
    console.error(err);
    console.error("Server Not Available");
    document.write("Maintenance - waiting to connect to server - come back in a few minutes")
})
