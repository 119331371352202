<template>
    <div
        class="lsc-login"
        v-bind:class="{
            'small-screen': screenType === 'small'
        }"
    >
        <p class="kds-h1">Line Optimizer Service Center</p>

        <p>
            Authenticate to download software browse licenses and get the latest
            news about the Line Optimizer product development.
        </p>

        <div class="kds-text-center kds-m-2" v-if="kcloginIsActive">
            <kds-button color="tertiary" @click="loginKeycloak">
                <span class="material-icons-outlined md-18 kc-icon"> key </span>
                <span class="kc-text">Go to Login</span>
            </kds-button>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            form: {
                Username: '',
                Password: ''
            },
            kcloginIsActive: true,
            errorText: ''
        };
    },
    name: 'LscLogin',
    props: {
        screenType: String
    },
    methods: {
        loginKeycloak() {
            this.$router.push({ path: '/app/lsc-home' });
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.lsc-login {
    margin: 2rem 35%;
    padding: 2rem;
    background-color: var(--kds-color-atmo3);
    box-shadow: 2px 2px 2px;
}

.lsc-login.small-screen {
    margin: 2rem 10%;
}

.lsc-login > h3 {
    text-align: left;
}

.input-group {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content min-content;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
        'label'
        'input';
    width: 100%;
    height: 100%;
    margin: 1rem 0;
}

.input-group > label {
    grid-area: label;
    text-align: left;
    font-weight: bold;
}

.input-group > input {
    grid-area: input;
}

.error-text {
    color: var(--kds-color-sema2);
    font-weight: bold;
}

button {
    background-color: var(--kds-color-acce2);
    color: var(--kds-color-atmo1);
    padding: 0.5rem 2rem;
    border-radius: 1rem;
    border: none;
    font-size: 1.2em;
    cursor: pointer;
}

.kc-icon {
    display: flex;
    float: left;
}

.kc-text {
    display: inline-block;
    padding: 0 0.4rem;
}

@media only screen and (max-device-width: 600px) {
    .lsc-login {
        margin: 2rem 0;
    }

    .lsc-login.small-screen {
        margin: 2rem 0;
    }
}
</style>
