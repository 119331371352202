const { reactive } = require("vue");
const { keycloakInitOptions } = require('./../config/config');

const store = {
    state: reactive({
        isAdmin: false,
        username: "LOGGED OUT",
        dragDisabled: false,
        popups: [],
        progress: {},
        keycloakInitOptions: keycloakInitOptions,
        accountConfigUrl: "/"
    }),
    setAdminState(newValue) {
        this.state.isAdmin = newValue === true;
    },
    setUser(userData) {
        this.state.isAdmin = userData.is_admin ? userData.is_admin : false;
        this.state.username = userData.preferred_username ? userData.preferred_username : userData.username ? userData.username : "LOGGED OUT";
    },
    setProgress(progressId, pg) {
        this.state.progress[progressId] = pg;
    },
    setDragDisabled(dragDisabled, changedElement) {
        let elementFound = false;
        let onePopupIsOpen = dragDisabled;

        for (let index = 0; index < this.state.popups.length; index++) {
            const popup = this.state.popups[index];

            if (popup.element === changedElement) {
                popup.open = dragDisabled;
                elementFound = true;
            }

            if (popup.open) {
                onePopupIsOpen = true;
            }
        }

        if (!elementFound) {
            this.state.popups.push({
                element: changedElement,
                open: dragDisabled
            });
        }
        this.state.dragDisabled = onePopupIsOpen;
    },
    setKeycloakInitOptions(keycloakInitOptions) {
        this.state.keycloakInitOptions = {...this.state.keycloakInitOptions, ...keycloakInitOptions};
        this.state.accountConfigUrl = `${this.state.keycloakInitOptions.url}/realms/${this.state.keycloakInitOptions.realm}/account/#/personal-info`
    }
}

module.exports = {
    store
};
