import { createRouter, createWebHistory } from 'vue-router';
import LscFrontpage from '@/views/LscFrontpage.vue';
import PageNotFound from '@/views/PageNotFound.vue';

const routes = [
    {
        path: '/',
        name: 'Initial Login',
        component: LscFrontpage,
        beforeEnter: [redirectToHomeIfAuthenticated]
    },
    {
        path: '/app/login',
        name: 'Login',
        component: LscFrontpage,
        beforeEnter: [redirectToHomeIfAuthenticated]
    },
    {
        path: '/app/lsc-home/:nodeId?/:tabName?/:elementId?',
        name: 'Line Optimizer Service Portal',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/LscHome.vue'),
        beforeEnter: [authCheck]
    },
    { 
        path: '/:pathMatch(.*)*', 
        component: PageNotFound 
    }
];

function authCheck(to, from, next) {
    if (window.keycloakInitDone === true) {
        if (window.keycloak?.authenticated === true) {
            // all good, redirect
            next();
        } else {
            // redirect to login with full href
            let tmpRedirect = new URL(window.location.origin);
            tmpRedirect.pathname = to && to.path ? to.path : '/app/lsc-home';

            window.keycloak.login({
                redirectUri:  tmpRedirect.href
            });
        }
    } else {
        // wait for keycloak to be initialized
        setTimeout(() => {
            authCheck(to, from, next);
        }, 50);
    }
}

function redirectToHomeIfAuthenticated(to, from, next) {
    if (window.keycloak?.authenticated === true) {
        next('/app/lsc-home');
    } else {
        setTimeout(() => {
            // console.log('guard after 500ms');
            if (window.keycloak?.authenticated === true) {
                router.push('/app/lsc-home');
            }
        }, 500);
        // go to page
        next();
    }
}

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
